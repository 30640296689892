h1 {
  text-align: center;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.container {
  max-width: 800px !important;
  margin-top: 0.5rem;
}

/* Taxed Amount */
.taxed-amount {
  margin-bottom: 0.5rem;
}

.taxed-amount .results__type {
  color: hsl(9, 100%, 45%);
}

.earned-amount .results__type {
  color: hsl(120, 61%, 35%);
}

.taxed-amount .results__amount {
  color: tomato;
}

/* Earned Amount */
.earned-amount {
  margin-bottom: 1rem;
}

.earned-amount .results__amount {
  color: limegreen;
}

/* Results */
.results {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}

.results__type {
  font-weight: bold;
}

.results__percent {
  color: #999;
}

/* Actions */
.actions {
  display: flex;
  margin-top: 0.5rem;
}

.actions button:not(:last-child) {
  margin-right: 0.5rem;
}

/* Description */
.description {
  margin-top: 0.5rem;
}

@media (max-width: 575px) {
  .container {
    max-width: none !important;
  }

  .form {
    margin-bottom: 1rem;
  }

  .form__pre-tax-income {
    margin-bottom: 1rem;
  }

  .results {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .actions {
    flex-direction: column;
  }

  .actions button:not(:last-child) {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}
